import React from 'react'
import InputAdornment from '@mui/material/InputAdornment';
import { Grid, Select, InputLabel, Autocomplete, TextField, Button, Stack } from '@mui/material';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import { MuiTelInput } from 'mui-tel-input'
import { useSnackbar } from 'notistack';

import Specialite from '../../../../components/UI/Specialite/Specialite'
import Header from 'components/UI/Header/Header';
import FlexFooter from 'components/UI/FlexFooter';
import { MAPBOX_ACCESS_TOKEN } from 'config/index'

const CreateIntervenant = ({ setIntervenants, close }) => {
    const [form, setForm] = React.useState({
        prenom: "", nom: "", mail: [], telephone: "", telephone_fixe: "", mo: "0", deplacement: "0", forfait: "0",
        societe: "", specialite: "Sélectionner", ville: "", adresse: "", adresse_number: "", postal: "", pays: "Sélectionner",
        latitude: "", longitude: "",
    })
    const [adresses, setAdresses] = React.useState([])
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        Promise.all([
            getAdresses()
        ]).then(([adresses]) => {
            if (adresses) {
                setAdresses(adresses.data.features)
            }
        })
    }, [form.adresse])

    const getAdresses = () => {
        axios.defaults.withCredentials = false
        if (form.adresse.length > 5) {
            return axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + form.adresse + '.json?&autocomplete=true&types=district%2Cpostcode%2Clocality%2Cplace%2Cneighborhood%2Caddress%2Cpoi&language=fr&routing=true&limit=10&country=' + form.pays + '&access_token=' + MAPBOX_ACCESS_TOKEN)
        }
    }
    const createNewIntervenant = () => {
        if (form.specialite === "Sélectionner") {
            enqueueSnackbar('Vous devez sélectionner une spécialitée !', {
                variant: 'warning',
            });
        } else {
            if (adresses.length > 0) {
                const header = {
                    longitude: form.longitude,
                    latitude: form.latitude,
                    nom: form.nom,
                    prenom: form.prenom,
                    mail: form.mail,
                    telephone: form.telephone,
                    telephone_fixe: form.telephone_fixe,
                    adresse_number: form.adresse_number,
                    adresse: form.adresse,
                    societe: form.societe,
                    specialite: form.specialite,
                    deplacement: form.deplacement,
                    mo: form.mo,
                    commentaire: form.commentaire,
                    pays: form.pays,
                    forfait: form.forfait,
                    postal: form.postal,
                    ville: form.ville,
                    fiabilite: 3,
                }
                axios.defaults.withCredentials = true
                axios.post("/legacy/CreateIntervenant", header)
                    .then(response => {
                        setIntervenants(prevState => [...prevState, response.data.artisan])
                        enqueueSnackbar("Intervenant crée", {
                            variant: 'success',
                        });
                        close()
                    }).catch(error => {
                        enqueueSnackbar("Erreur lors de l'ajout de l'intervenant !", {
                            variant: 'error',
                        });
                    });
            } else {
                enqueueSnackbar("Adresse ou ville introuvable !", {
                    variant: 'error',
                });
            }
        }
    }

    const handleAutoCompleteChange = (event, value) => {
        let codepostale = ""
        let ville = ""
        if (value.context) {
            value.context.forEach(item => {
                if (item.id.split('.').shift() === "postcode")
                    codepostale = item.text
                if (item.id.split('.').shift() === "place")
                    ville = item.text
                return null
            })
        }
        setForm({ ...form, "adresse": value.text_fr, "postal": codepostale, "ville": ville, "longitude": value.geometry.coordinates[0].toString(), "latitude": value.geometry.coordinates[1].toString() })
    };

    const handleTextFieldChange = (event, key) => {
        if (key === "prenom" || key === "ville") {
            let text = event.target.value.toLowerCase()
            text = text.charAt(0).toUpperCase() + text.slice(1)
            setForm({ ...form, [key]: text })
        } else if (key === "nom") {
            let text = event.target.value.toUpperCase()
            setForm({ ...form, [key]: text })
        } else if (key === "societe" || key === "adresse_number") {
            let text = event.target.value.toUpperCase()
            setForm({ ...form, [key]: text })
        } else if (key === "mail") {
            let mail = form.mail
            mail[0] = event.target.value
            setForm({ ...form, [key]: mail })
        } else if (key === "mail2") {
            let mail = form.mail
            mail[1] = event.target.value
            setForm({ ...form, [key]: mail })
        } else if (key === "specialite" || key === "adresse") {
            setForm({ ...form, [key]: event.target.value })
        } else {
            let text = event.target.value.toLowerCase()
            setForm({ ...form, [key]: text })
        }
    };

    const handlePhoneChange = (event, key) => {
        setForm({ ...form, [key]: event })
    };

    const handleChangePays = (event) => {
        setForm({
            ...form,
            pays: event.target.value,
        });
    };

    return (
        <React.Fragment>
            <Header>Nouvel intervenant</Header>
            <Grid container spacing={1} sx={{ px: 2 }}>
                <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            label="Société" variant="outlined"
                            value={form.societe}
                            type="text"
                            placeholder="MASTORE"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "societe")}
                            name="Socitété"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="Adresse"
                            value={form.adresse}
                            onChange={(event, value) => handleAutoCompleteChange(event, value)}
                            size="small"
                            options={adresses}
                            fullWidth={true}
                            getOptionLabel={(option) => {
                                if (option.place_name_fr)
                                    return (option.place_name_fr)
                                else
                                    return (option)
                            }}
                            disabled={form.pays === "Sélectionner" ? true : false}
                            isOptionEqualToValue={(option, value) => { return true }}
                            style={{ width: "100%", marginRight: "16px" }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                    onChange={(event) => handleTextFieldChange(event, "adresse")}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ background: "white" }}
                                    label="Adresse"
                                    variant="outlined"
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Nom" variant="outlined"
                            value={form.nom}
                            type="text"
                            placeholder="NOM"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "nom")}
                            name="Nom"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Prénom" variant="outlined"
                            value={form.prenom}
                            type="text"
                            placeholder="Prénom"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "prenom")}
                            name="Prénom"
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mail 1" variant="outlined"
                            value={form.mail[0]}
                            type="email"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "mail")}
                            name="Mail"
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                label="Main d'œuvre" variant="outlined"
                                value={form.mo}
                                type="number"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "mo")}
                                name="Main d'œuvre"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                size='small'
                            />
                            <TextField
                                label="Déplacement" variant="outlined"
                                value={form.deplacement}
                                type="number"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "deplacement")}
                                name="Deplacement"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                size='small'
                            />
                            <TextField
                                label="Forfait" variant="outlined"
                                value={form.forfait}
                                type="number"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "forfait")}
                                name="Forfait"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                size='small'
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={6} spacing={1}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{ width: "100%" }}>
                            <InputLabel >Pays</InputLabel>
                            <Select
                                native
                                value={form.pays}
                                onChange={handleChangePays}
                                label="Pays"
                                style={{ background: "white" }}
                                size='small'
                            >
                                <option value="Sélectionner">Sélectionner un Pays</option>
                                <option value="be">BELGIQUE</option>
                                <option value="fr">FRANCE</option>
                                <option value="de">ALLEMAGNE</option>
                                <option value="it">ITALIE</option>
                                <option value="lu">LUXEMBOURG</option>
                                <option value="pt">PORTUGAL</option>
                                <option value="es">ESPAGNE</option>
                                <option value="ch">SUISSE</option>
                                <option value="nl">PAYS-BAS</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                label="N°" variant="outlined"
                                value={form.adresse_number}
                                type="text"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "adresse_number")}
                                style={{ width: "20%", background: "white" }}
                                name="Numéro rue"
                                disabled={form.pays === "Sélectionner" ? true : false}
                                size='small'
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="Code Postal" variant="outlined"
                                value={form.postal}
                                type="text"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "postal")}
                                style={{ width: "25%", background: "white" }}
                                name="Code Postal"
                                size='small'
                                disabled={form.pays === "Sélectionner" ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="Ville" variant="outlined"
                                value={form.ville}
                                type="text"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "ville")}
                                style={{ width: "50%", background: "white" }}
                                name="ville"
                                disabled={form.pays === "Sélectionner" ? true : false}
                                size='small'
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <MuiTelInput
                            defaultCountry={'FR'}
                            value={form.telephone}
                            onChange={(event) => handlePhoneChange(event, "telephone")}
                            onlyCountries={['FR', 'ES', 'CH', 'BE', 'IT', 'DE', 'PT', 'LU', 'MC', 'NL']}
                            size="small"
                            label="Téléphone"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MuiTelInput
                            defaultCountry={'FR'}
                            value={form.telephone_fixe}
                            onChange={(event) => handlePhoneChange(event, "telephone_fixe")}
                            onlyCountries={['FR', 'ES', 'CH', 'BE', 'IT', 'DE', 'PT', 'LU', 'MC', 'NL']}
                            size="small"
                            label="Téléphone Fixe"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mail 2"
                            variant="outlined"
                            value={form.mail[1]}
                            type="email"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "mail2")}
                            name="Mail"
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{ width: "100%" }}>
                            <InputLabel>Spécialité</InputLabel>
                            <Specialite value={form.specialite} changeSpecialite={(event) => handleTextFieldChange(event, "specialite")} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Commentaire"
                        multiline
                        minRows={2}
                        maxRows={5}
                        variant="outlined"
                        value={form.commentaire}
                        type="text"
                        autoComplete="new-password"
                        onChange={(event) => handleTextFieldChange(event, "commentaire")}
                        name="commentaire"
                        size='small'
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <FlexFooter>
                <Button
                    onClick={createNewIntervenant}
                    variant="outlined"
                    style={{ margin: "auto" }}
                >
                    Ajouter l'intervenant
                </Button>
            </FlexFooter>
        </React.Fragment >
    );
};

export default CreateIntervenant;