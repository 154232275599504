import React from 'react'
// mui
import { Stack, TextField, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonGroup from '@mui/material/ButtonGroup';
// components
import VilleAutocomplete from './VilleAutocomplete';
import CSVextraction from 'components/UI/CSVextraction';

// ----------------------------------------------------------------------

const ButtonGroupStyle = styled(Button)(({ theme, selected }) => ({
    textTransform: 'lowercase',
    ...(selected && {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    })
}));

// ----------------------------------------------------------------------

const NavBarTop = ({ setModalCreateIntervenant, filter, onChangeFilter, filteredIntervenant }) => {
    const [allSpecialite, setAllSpecialite] = React.useState([])
    React.useEffect(() => {
        let NewAllSpecialite = []
        filteredIntervenant.map(item => {
            if (!NewAllSpecialite.includes(item.specialite))
                NewAllSpecialite.push(item.specialite)
            return 0
        })
        setAllSpecialite(NewAllSpecialite)
    }, [filteredIntervenant])

    return (
        <Grid item xs={12} sx={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 1px 2px", zIndex: "2", p: 1 }} alignItems="center">
            <Stack direction={"row"} spacing={2} sx={{ width: '100%' }}>
                <Stack direction={"row"} spacing={1}>
                    <Autocomplete
                        id="societe"
                        value={filter.societe}
                        onChange={(event, value) => onChangeFilter(value?.societe ? value.societe : null, "societe")}
                        options={
                            //Remove duplicate societe
                            filteredIntervenant.filter((item, index, self) =>
                                index === self.findIndex((t) => (
                                    t.societe === item.societe
                                ))
                            )
                        }
                        getOptionLabel={(option) => option.societe ?? option}
                        isOptionEqualToValue={(option, value) => {
                            return option.societe === value;
                        }}
                        style={{ width: 150 }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size="small"
                                label="Société"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        }
                    />
                    <Autocomplete
                        id="specialite"
                        size="small"
                        value={filter.specialite}
                        onChange={(event, value) => onChangeFilter(value, "specialite")}
                        options={allSpecialite}
                        style={{ width: 160 }}
                        renderInput={
                            (params) =>
                                <TextField
                                    {...params}
                                    label="Specialité"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                        }
                    />
                    <VilleAutocomplete onChangeFilter={onChangeFilter} filter={filter} />
                </Stack>
                {filter.ville !== null ?
                    <ButtonGroup variant="outlined" size='small'>
                        <ButtonGroupStyle selected={filter.distance === 25} onClick={() => onChangeFilter(25, "distance")}>25 km</ButtonGroupStyle>
                        <ButtonGroupStyle selected={filter.distance === 50} onClick={() => onChangeFilter(50, "distance")}>50 km</ButtonGroupStyle>
                        <ButtonGroupStyle selected={filter.distance === 75} onClick={() => onChangeFilter(75, "distance")}>75 km</ButtonGroupStyle>
                        <ButtonGroupStyle selected={filter.distance === 100} onClick={() => onChangeFilter(100, "distance")}>100 km</ButtonGroupStyle>
                        <ButtonGroupStyle selected={filter.distance === 200} onClick={() => onChangeFilter(200, "distance")}>200 km</ButtonGroupStyle>
                        <ButtonGroupStyle selected={filter.distance === 300} onClick={() => onChangeFilter(300, "distance")}>300 km</ButtonGroupStyle>
                    </ButtonGroup>
                    : null}
                <Button
                    startIcon={<AddIcon />}
                    sx={{ margin: '0px 20px 0px auto !important' }}
                    size="small"
                    variant="outlined"
                    onClick={() => setModalCreateIntervenant(true)}
                >
                    Créer un intervenant
                </Button>
                <CSVextraction
                    tableColumns={[
                        { id: 'societe', type: 'string', label: 'Société' },
                        { id: 'fiabilite', type: 'string', label: 'Fiabilité' },
                        { id: 'nom', type: 'string', label: 'Nom' },
                        { id: 'prenom', type: 'string', label: 'Prénom' },
                        { id: 'telephone', type: 'string', label: 'Téléphone' },
                        { id: 'telephone_fixe', type: 'string', label: 'Téléphone Fixe' },
                        { id: 'mail', type: 'string', label: 'Email' },
                        { id: 'adresse_number', type: 'string', label: 'Numéro Adresse' },
                        { id: 'adresse', type: 'string', label: 'Adresse' },
                        { id: 'postal', type: 'string', label: 'Code postal' },
                        { id: 'ville', type: 'string', label: 'Ville' },
                        { id: 'specialite', type: 'string', label: 'Spécialité' },
                        { id: 'commentaire', type: 'string', label: 'Commentaire' },
                    ]}
                    data={filteredIntervenant}
                />
            </Stack>
        </Grid >
    )
}

export default NavBarTop