import React, { useEffect, useRef, useState } from "react";
import { Card, Grid, TextField, Stack, Box, IconButton } from "@mui/material";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { styled } from "@mui/material/styles";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import MapIcon from "@mui/icons-material/Map";
import DescriptionIcon from "@mui/icons-material/Description";
import Modal from "components/UI/Modal";
import InventoryIcon from "@mui/icons-material/Inventory";
import Commentaire from "./Commentaire/Commentaire";
import ModalButton from "components/UI/ModalButton";
import Marge from "./Marge/Marge";
import SAVButton from "./SAVButton";
import AstreinteButton from "./AstreinteButton";
import InterventionCalendar from "./InterventionCalendar/InterventionCalendar";
import CommandeStocks from "../modal/CommandeStock/CommandeStock";

import StockService from "services/stocksService";
import EditorJS from "@editorjs/editorjs";
import Paragraph from "@editorjs/paragraph";
import Underline from "@editorjs/underline";

const stockService = new StockService();

const ExtandableTextArea = styled("textarea", {
    shouldForwardProp: (prop) => prop !== "expand",
})(({ theme, expand }) => ({
    border: "1px solid rgb(196 196 196)",
    resize: "none",
    padding: "8px 14px",
    height: "100%",
    borderRadius: "4px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: theme.typography.body2.fontSize,
    outline: "none",
    width: "100%",
    zIndex: 0,
    "&:hover": {
        border: "1px solid #000",
    },
    "&:focus": {
        border: "2px solid #30668f",
    },
    ...(expand && {
        zIndex: 1,
        position: "absolute",
        bottom: 0,
    }),
}));

const BoxRelative = styled(Box, {
    shouldForwardProp: (prop) => prop !== "expand",
})(({ expand }) => ({
    height: "100%",
    width: "100%",
    position: "relative",
    ...(expand && {
        zIndex: 3,
        position: "absolute",
        bottom: 0,
    }),
}));

// ----------------------------------------------------------------------

const InterventionInformation = (props) => {
    const [expandPrestation, setExpandPrestation] = useState(false);
    const [expandDemande, setExpandDemande] = useState(false);
    const [openCommandeStock, setOpenCommandeStock] = useState(false);
    const [isCommandeEmpty, setIsCommandeEmpty] = useState(true);
    const editorInstance = useRef(null);
    const editorRef = useRef(null);

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const formatString = (value) => {
        props.updateInfoIntervention(
            capitalize(
                value
                    .trim()
                    .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")
                    .toLowerCase()
            ),
            "objet"
        );
    };

    useEffect(() => {
        stockService.getFicheCommande(props.infoIntervention.id_mastore)
            .then((data) => {
                setIsCommandeEmpty(data.length === 0); // Set state based on data
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [props.infoIntervention.id_mastore]);

    // Initialize Editor.js only once
    useEffect(() => {
        if (!editorRef.current) return;

        const initialData = props.infoIntervention.complement_objet;

        // Convertir le texte brut en blocs JSON
        const blocks = initialData.split(/\n/).map((text, index) => {
            return {
                id: `block_${index}`, // Générer un ID unique pour chaque bloc
                type: 'paragraph', // Ou un autre type selon le contenu
                data: { text: text.trim() }
            };
        }).filter(block => block.data.text); // Ignorer les blocs vides

        editorInstance.current = new EditorJS({
            holder: editorRef.current,
            tools: {
                paragraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                    config: {
                        preserveBlank: true,
                    },
                },
                underline: { class: Underline, inlineToolbar: true },
            },
            data: {
                blocks: blocks,
                version: "2.8.1" // Version d'Editor.js
            },
            i18n: {
                messages: {
                    ui: {
                        inlineToolbar: {
                            converter: "Convertir",
                        },
                    },
                    toolNames: {
                        "Text": "Texte",
                        "List": "Liste",
                        "Link": "Lien",
                        "Underline": "Souligner",
                        "Bold": "Gras",
                        "Italic": "Italique",
                    },
                    blockTunes: {
                        "delete": {
                            "Delete": "Supprimer"
                        },
                        "moveUp": {
                            "Move up": "Déplacer vers le haut"
                        },
                        "moveDown": {
                            "Move down": "Déplacer vers le bas"
                        },
                    },
                },
            },
            onChange: saveEditorData,
            placeholder: "Entrez votre texte ici...",
            autofocus: true,
        });

        return () => {
            if (editorInstance.current) {
                editorInstance.current.destroy();
                editorInstance.current = null;
            }
        };
    }, []);


    // Save Editor.js data
    const saveEditorData = async () => {
        try {
            const savedData = await editorInstance.current.save();
            const textData = savedData.blocks
                .map(block => {
                    if (block.type === 'paragraph') {
                        return `<p>${block.data.text.replace(/\n/g, "<br/>")}</p>`;
                    }
                    if (block.type === 'header') {
                        return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
                    }
                    return '';
                })
                .filter(text => text)
                .join("\n"); // Utiliser un saut de ligne pour séparer les blocs

            props.updateInfoIntervention(textData, "complement_objet");
        } catch (error) {
            console.error('Saving failed:', error);
        }
    };


    return (
        <Card variant="outlined" sx={{ p: 1 }}>
            <Stack direction="column" sx={{ height: "100%" }} spacing={1}>
                <Grid container spacing={0.5} sx={{ flex: "1 1 auto" }}>
                    <Grid item xs={12} sm={9}>
                        <Stack spacing={1} sx={{ height: "100%", position: "relative" }}>
                            <TextField
                                defaultValue={props.infoIntervention.objet}
                                placeholder="Objet de la demande"
                                variant="outlined"
                                size="small"
                                onBlur={(event) => formatString(event.target.value)}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                            <BoxRelative
                                expand={expandDemande}
                                style={
                                    !expandDemande
                                        ? props.infoIntervention.type === "préventive"
                                            ? { height: "30%" }
                                            : { height: "40%" }
                                        : null
                                }
                            >
                                <IconButton
                                    color={expandDemande ? "primary" : "secondary"}
                                    component="span"
                                    style={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}
                                    onClick={() => setExpandDemande(!expandDemande)}
                                >
                                    <FitScreenIcon />
                                </IconButton>
                                <ExtandableTextArea
                                    placeholder="Demande initiale"
                                    rows={4}
                                    value={props.infoIntervention.initial_request}
                                    onChange={(event) =>
                                        props.updateInfoIntervention(
                                            event.target.value,
                                            "initial_request"
                                        )
                                    }
                                    expand={expandDemande}
                                />
                            </BoxRelative>
                            <BoxRelative expand={expandPrestation}
                                         sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                                <IconButton
                                    color={expandPrestation ? "primary" : "secondary"}
                                    component="span"
                                    style={{position: "absolute", top: 0, right: 0, zIndex: 2}}
                                    onClick={() => setExpandPrestation(!expandPrestation)}
                                >
                                    <FitScreenIcon/>
                                </IconButton>
                                <div
                                    id="editorjs"
                                    ref={editorRef}
                                    style={{
                                        padding: "10px",
                                        minHeight: "100px",
                                        maxHeight: expandPrestation ? "none" : "300px", // Ajuster la hauteur pour un défilement adapté
                                        border: "1px solid rgb(196 196 196)",
                                        borderRadius: "4px",
                                        background: "#fff",
                                        overflowY: "auto", // Forcer un défilement si nécessaire
                                        flexGrow: 1,
                                    }}
                                />
                            </BoxRelative>
                            <Commentaire IDMastore={props.infoIntervention.id_mastore}/>
                        </Stack>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Stack direction={"column"} spacing={1}>
                            {!JSON.parse(localStorage.getItem("clientView")) ? (
                                <Marge
                                    actions={props.actions}
                                    factureClient={props.factureClient}
                                    devisClient={props.devisClient}
                                />
                            ) : null}
                            <Box
                                sx={{
                                    height: "fit-content",
                                    display: { xs: "none", md2: "flex" },
                                    justifyContent: "center",
                                }}
                            >
                                <InterventionCalendar
                                    actions={props.actions}
                                    dateMax={props.infoIntervention.date_max}
                                />
                            </Box>
                            <TextField
                                multiline
                                label="Spécificité(s) Mastore"
                                defaultValue={props.infoIntervention.specificite_mastore}
                                placeholder="Spécificité(s) Mastore"
                                variant="outlined"
                                size="small"
                                rows={10}
                                rowsmax={10}
                                onBlur={(event) =>
                                    props.updateInfoIntervention(
                                        event.target.value,
                                        "specificite_mastore"
                                    )
                                }
                                InputLabelProps={{ shrink: true }}
                            />
                            <SAVButton
                                infoIntervention={props.infoIntervention}
                                updateInfoIntervention={props.updateInfoIntervention}
                            />
                            <AstreinteButton
                                infoIntervention={props.infoIntervention}
                                updateInfoIntervention={props.updateInfoIntervention}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Stack direction={"row"} justifyContent="space-around">
                    <ModalButton
                        name="Multimédia(s)"
                        filled={props.boolPhotos > 0}
                        clickHandler={props.openModalPhoto}
                        icon={<PhotoLibraryIcon />}
                    />
                    <ModalButton
                        name="Plan(s)"
                        filled={props.filePlanLength > 0}
                        clickHandler={props.openPlanModal}
                        icon={<MapIcon />}
                    />
                    <ModalButton
                        name="Document(s)"
                        filled={props.fileDocumentLength > 0}
                        clickHandler={props.openDocumentModal}
                        icon={<DescriptionIcon />}
                    />
                    <ModalButton
                        name="Commande Stocks"
                        filled={!isCommandeEmpty}
                        clickHandler={() => setOpenCommandeStock(true)}
                        icon={<InventoryIcon />}
                    />
                </Stack>
                <Modal
                    open={openCommandeStock}
                    close={() => setOpenCommandeStock(false)}
                    padding="0px"
                >
                    <CommandeStocks
                        infoIntervention={props.infoIntervention}
                        user={props.user}
                        actions={props.actions}
                    />
                </Modal>
            </Stack>
        </Card>
    );
};

export default InterventionInformation;
