import React, { useState } from 'react';
import {
    Button,
    Stack,
    Typography,
    Grid,
    Checkbox,
    FormControlLabel,
    Box,
    TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Autocomplete from '@mui/material/Autocomplete';
// Components
import Modal from "components/UI/Modal";
import FlexFooter from './FlexFooter';
import Header from 'components/UI/Header/Header';

// Fonction pour télécharger le fichier CSV
const downloadCSV = (data, columns, filename) => {
    let csv = data.map(row => {
        return columns.map(column => {
            if (row[column.id] === "0001-01-01T00:00:00Z") {
                return "";
            } else if (column.type === 'date') {
                const dateValue = new Date(row[column.id]);
                // Vérifie si la date est "01 janv. 0001", dans ce cas, retourne une chaîne vide
                if (dateValue.getFullYear() === 1 && dateValue.getMonth() === 0 && dateValue.getDate() === 1) {
                    return "";
                } else {
                    return dateValue.toLocaleDateString('fr-FR');
                }
            } else {
                return row[column.id];
            }
        }).join(';');
    }).join('\n');

    let csvFile;
    let downloadLink;

    // Créer le header du fichier CSV
    let header = columns.map(column => column.label).join(';');

    // Ajouter le header au début du fichier CSV
    csv = header + '\n' + csv;
    csv = '\ufeff' + csv; // Ajouter BOM pour support UTF-8 dans Excel

    csvFile = new Blob([csv], { type: "text/csv;charset=utf-8" });

    // Créer un lien d'ancrage qui sera utilisé pour télécharger le fichier CSV
    downloadLink = document.createElement("a");

    // Configurer le lien d'ancrage
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
};

const CSVextraction = ({ tableColumns, data, buttonLabel = "Exporter" }) => {
    const [modalCreateCSV, setModalCreateCSV] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [loading, setLoading] = useState(false);

    // Extraire la liste unique des clients à partir des données
    const clients = Array.from(new Set(data.map(row => row.client))).map(client => ({ label: client }));

    const checkOrUncheckAllColumn = () => {
        if (selectedColumns.length === tableColumns.length) {
            setSelectedColumns([]);
        } else {
            setSelectedColumns(tableColumns.map((column) => ({ id: column.id, type: column.type, label: column.label })));
        }
    };

    const downloadClick = () => {
        if (selectedColumns.length === 0) {
            alert("Sélectionnez au moins une colonne.");
        } else {
            setLoading(true);
            // Filtrer les données en fonction du client sélectionné, si un client est choisi
            const filteredData = selectedClient
                ? data.filter(row => row.client === selectedClient.label)
                : data;

            downloadCSV(filteredData, selectedColumns, 'MastoreERP_Extraction.csv');
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Button
                size='small'
                startIcon={<FileDownloadIcon />}
                variant='outlined'
                onClick={() => setModalCreateCSV(true)}
            >
                {buttonLabel}
            </Button>
            <Modal open={modalCreateCSV} close={() => setModalCreateCSV(false)} sx={{ width: { xl: "40vw", md: "60vw", md2: "50vw", sm: "80vw", xs: "90vw" } }}>
                <Header>Extraction CSV</Header>
                <Stack sx={{ px: 2 }} spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='h6'>Sélectionnez les données à extraire</Typography>
                        </Grid>
                        {tableColumns.map((column) => {
                            if (column.id === 'commentaire') {
                                return null;
                            }
                            const isChecked = selectedColumns.some(c => c.id === column.id);
                            const handleCheckboxChange = () => {
                                if (isChecked) {
                                    setSelectedColumns(selectedColumns.filter(c => c.id !== column.id));
                                } else {
                                    setSelectedColumns([...selectedColumns, { id: column.id, type: column.type, label: column.label }]);
                                }
                            };
                            return (
                                <Grid item xs={3} key={column.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={column.id}
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                        }
                                        label={column.label}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Button
                        variant='outlined'
                        size='small'
                        onClick={checkOrUncheckAllColumn}
                    >
                        Tout sélectionner / déselectionner
                    </Button>

                    <Box mt={2}>
                        <Autocomplete
                            options={clients}
                            getOptionLabel={(option) => option.label}
                            value={selectedClient}
                            onChange={(event, newValue) => setSelectedClient(newValue)}
                            renderInput={(params) => <TextField {...params} label="Sélectionner un client" variant="outlined" />}
                        />
                    </Box>

                    <Box>
                        <Alert severity="info">Les données extraites sont celles qui se trouvent actuellement dans le tableau</Alert>
                    </Box>
                </Stack>
                <FlexFooter>
                    <LoadingButton
                        loading={loading}
                        variant="outlined"
                        onClick={downloadClick}
                    >
                        Créer et télécharger le fichier CSV
                    </LoadingButton>
                </FlexFooter>
            </Modal>
        </React.Fragment>
    );
};

export default CSVextraction;
