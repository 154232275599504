import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { MAPBOX_ACCESS_TOKEN } from 'config/index'

export default function VilleAutocomplete({ onChangeFilter, filter }) {
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const getVille = () => {
        axios.defaults.withCredentials = false
        return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${inputValue}.json?country=fr%2Cbe%2Clu%2Cch%2Cde%2Cit%2Cmc%2Ces&limit=10&types=place&language=fr&access_token=${MAPBOX_ACCESS_TOKEN}`)
    }

    React.useEffect(() => {
        Promise.all([
            getVille()
        ]).then(([cities]) => {
            setOptions(cities.data.features);
        })
    }, [inputValue]);

    return (
        <Autocomplete
            sx={{ width: 160 }}
            size="small"
            getOptionLabel={(option) =>
                option.place_name_fr
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={filter.ville}
            noOptionsText="Pas d'options"
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                onChangeFilter(newValue, "ville")
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Ville"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
}