import React, { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Paragraph from "@editorjs/paragraph";
import Underline from "@editorjs/underline";
import PropTypes from 'prop-types';
import { Box } from "@mui/material";

const CustomEditor = ({
                          initialData = "",
                          onSave,
                          placeholder = "Entrez votre texte ici...",
                          customStyles = {},
                          tools = {
                              paragraph: {
                                  class: Paragraph,
                                  inlineToolbar: true,
                                  config: {
                                      preserveBlank: true,
                                  },
                              },
                              underline: { class: Underline, inlineToolbar: true },
                          }
                      }) => {
    const editorInstance = useRef(null);
    const editorRef = useRef(null);

    useEffect(() => {
        if (!editorRef.current) return;

        // Convertir le texte brut en blocs JSON
        const blocks = initialData.split(/\n/).map((text, index) => {
            return {
                id: `block_${index}`,
                type: 'paragraph',
                data: { text: text.trim() }
            };
        }).filter(block => block.data.text);

        editorInstance.current = new EditorJS({
            holder: editorRef.current,
            tools: tools,
            data: {
                blocks: blocks,
                version: "2.8.1"
            },
            i18n: {
                messages: {
                    ui: {
                        inlineToolbar: {
                            converter: "Convertir",
                        },
                    },
                    toolNames: {
                        "Text": "Texte",
                        "List": "Liste",
                        "Link": "Lien",
                        "Underline": "Souligner",
                        "Bold": "Gras",
                        "Italic": "Italique",
                    },
                    blockTunes: {
                        "delete": {
                            "Delete": "Supprimer"
                        },
                        "moveUp": {
                            "Move up": "Déplacer vers le haut"
                        },
                        "moveDown": {
                            "Move down": "Déplacer vers le bas"
                        },
                    },
                },
            },
            onChange: saveEditorData,
            placeholder: placeholder,
            autofocus: true,
        });

        return () => {
            if (editorInstance.current) {
                editorInstance.current.destroy();
                editorInstance.current = null;
            }
        };
    }, []);

    const saveEditorData = async () => {
        try {
            const savedData = await editorInstance.current.save();
            const textData = savedData.blocks
                .map(block => {
                    if (block.type === 'paragraph') {
                        return `<p>${block.data.text.replace(/\n/g, "<br/>")}</p>`;
                    }
                    return '';
                })
                .filter(text => text)
                .join("\n");

            onSave(textData);
        } catch (error) {
            console.error('Saving failed:', error);
        }
    };

    return (
        <Box
            ref={editorRef}
            sx={{
                padding: "5px", // Réduit le padding
                minHeight: "50px", // Diminue la hauteur minimum
                border: "1px solid rgb(196 196 196)",
                borderRadius: "4px",
                backgroundColor: "#fff",
                overflowY: "auto",
                ...customStyles, // Appliquer des styles personnalisés via props
                // Styles personnalisés via CSS en ligne
                '@media (min-width: 651px)': {
                    '.ce-block__content': {
                        maxWidth: 'calc(100% - 120px) !important',
                        margin: '0 60px',
                    },
                    '.ce-toolbar__content': {
                        width: '0px !important',
                        margin: '0 50px',
                    },
                    '.codex-editor--narrow .ce-toolbox .ce-popover': {
                        left: '0',
                        right: '0',
                    },
                    '.codex-editor--narrow .ce-settings .ce-popover': {
                        right: '0',
                        left: '0',
                    },
                },
                '.cdx-block': {
                    maxWidth: '100% !important',
                },
            }}
        />
    );
};

CustomEditor.propTypes = {
    initialData: PropTypes.string,
    onSave: PropTypes.func.isRequired, // Callback pour enregistrer les données
    placeholder: PropTypes.string,
    customStyles: PropTypes.object, // Styles personnalisés via props
    tools: PropTypes.object, // Pour ajouter ou modifier les outils d'Editor.js
};

export default CustomEditor;
