import React, { Component } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';

import { ButtonAdd, IconAdd, SaveButton, DivButton } from './DocumentStyle';
import ModalInfosDocument from './ModalInfosDocument/ModalInfosDocument';
import ModalEditInfosDocument from './ModalInfosDocument/ModalEditInfosDocument';
import Header from 'components/UI/Header/Header';
import TableDocument from './TableDocument';

class Document extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: this.props.filesDocument,
            waitFiles: [],
            dataFiles: this.props.filesDocument,
            openDataFile: false,
            openEditDataFile: false,
            textInfo: "Ajouter des fichiers",
            editFile: [],
            alert: null,
            hasChanges: false,
        };
    }

    onDrop(files) {
        let SizeOk = true;
        files.forEach(file => {
            if (file.size > 6000000) {
                SizeOk = false;
            }
        });
        if (SizeOk) {
            let waitFiles = this.state.waitFiles.concat(files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            this.setState({
                waitFiles: waitFiles,
                openDataFile: true,
                alert: null,
                hasChanges: true,
            });
        } else {
            this.setState({ alert: <Alert style={{ margin: 'auto', marginBottom: "20px", border: "1px solid", width: 'fit-content' }} severity="warning">Limite de taille de fichier atteinte! Taille maximale 5Mo. Aucun des fichiers n'a été ajouté.</Alert> });
        }
    }

    addDocument = () => {
        this.props.addDocument(this.state.files);
        this.props.modalClosed();
        this.setState({ hasChanges: false });
    }

    removeFile = (_, file) => {
        let filteredFiles = this.state.files.filter(item => item !== file);
        this.setState({
            files: filteredFiles,
            dataFiles: filteredFiles,
            hasChanges: true
        });
    }

    saveOrCancel = (att, data) => {
        if (att === "save") {
            this.setState({
                files: this.state.files.concat(data),
                waitFiles: [],
                dataFiles: this.state.dataFiles.concat(data),
                openDataFile: false,
                hasChanges: true, // Set to true when data is saved
            });
        } else if (att === "cancel") {
            this.setState({
                waitFiles: [],
                openDataFile: false,
            });
        }
    }

    editFile = (_, file) => {
        this.setState({ editFile: this.state.editFile.concat(file), openEditDataFile: true });
    }

    saveOrCancelEditFile = (att, newFile, oldFile) => {
        if (att === "save") {
            let index = this.state.files.map(item => item).indexOf(oldFile);
            let filteredFiles = [...this.state.files];
            filteredFiles[index] = newFile[0];
            this.setState({
                files: filteredFiles,
                editFile: [],
                dataFiles: filteredFiles,
                openEditDataFile: false,
                hasChanges: true,
            });
        } else if (att === "cancel") {
            this.setState({
                editFile: [],
                openEditDataFile: false,
            });
        }
    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType + ';base64' });
        return blob;
    }

    openBase64InNewTab = (data, mimeType) => {
        let byteCharacters = atob(data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let file = new Blob([byteArray], { type: mimeType });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    showDocument = (event, row) => {
    let blob;
    if (row.name.includes(".pdf") || row.name.includes(".PDF")) {
        blob = this.b64toBlob(row.file, "application/pdf");
    } else if (row.name.includes(".csv")) {
        blob = this.b64toBlob(row.file, "text/csv");
    } else if (row.name.includes(".xlsx") || row.name.includes(".xls")) {
        blob = this.b64toBlob(row.file, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    } else if (row.name.includes(".docx")) {
        blob = this.b64toBlob(row.file, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
    } else {
        blob = this.b64toBlob(row.file, 'image/png;base64');
    }
    let url = URL.createObjectURL(blob);
    window.open(url);
    }



    render() {
        return (
            <Box sx={{ width: { md: "70vw", sm: "80vw", xs: "95vw" }, maxHeight: "80vh" }}>
                <Header>Documents</Header>
                <Stack sx={{ px: 3, maxHeight: "550px", overflow: 'auto' }} spacing={1}>
                    {this.state.openDataFile ? <ModalInfosDocument openDataFile={this.state.openDataFile} dataFiles={this.state.waitFiles} saveOrCancel={this.saveOrCancel} /> : null}
                    {this.state.openEditDataFile ? <ModalEditInfosDocument openDataFile={this.state.openEditDataFile} dataFiles={this.state.editFile} saveOrCancel={this.saveOrCancelEditFile} type="Photos" /> : null}
                    <Dropzone accept=".csv, .xlsx, .xls, .docx, image/*, application/pdf" onDrop={this.onDrop.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container" style={{ paddingLeft: "0px" }}>
                                <div style={{ width: "fit-content" }} {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <ButtonAdd variant="outlined" size='small' startIcon={<IconAdd />}>
                                        Ajouter document(s)
                                    </ButtonAdd>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <Stack style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        {this.state.dataFiles.length > 0 ? (
                            <Stack style={{ display: "flex", flexWrap: "wrap" }}>
                                {this.state.alert}
                                <TableDocument
                                    dataFiles={this.state.dataFiles}
                                    editFile={this.editFile}
                                    showDocument={this.showDocument}
                                    removeFile={this.removeFile}
                                />
                            </Stack>
                        ) : (
                            <Typography variant="h1" align="center" textAlign={"center"} color="rgba(99, 115, 129, 0.3)">
                                Aucun document
                            </Typography>
                        )}
                    </Stack>
                </Stack>
                <Stack sx={{ p: 3 }} direction="row" justifyContent="center" alignItems="center">
                    <DivButton>
                        <SaveButton
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            size='small'
                            onClick={this.addDocument}
                            disabled={!this.state.hasChanges}
                        >
                            Sauvegarder le(s) document(s)
                        </SaveButton>
                    </DivButton>
                </Stack>
            </Box>
        );
    }
}

export default Document;
