import React from 'react';
import mapboxgl from 'mapbox-gl'
import { Component } from 'react';
import { MAPBOX_ACCESS_TOKEN } from 'config/index'

import './map.css'

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class Mapbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: 2,
            lat: 46,
            zoom: 5,
            zoneMarker: null,
            markers: [],
            maps: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.intervenantLocation !== prevProps.intervenantLocation && this.state.maps !== null) {
            let newMarkers = []
            let newZoneMarker = this.state.zoneMarker

            // modifie la position des intervenants
            this.state.markers.forEach(item => {
                item.remove();
            })
            this.props.intervenantLocation.forEach(marker => {
                var popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    marker.properties.description
                );
                newMarkers.push(new mapboxgl.Marker({ color: "red" }).setLngLat(marker.geometry.coordinates).setPopup(popup).addTo(this.state.maps))
            });

            // modifie la position de la ville sélectionnée
            if (this.props.selectedVille) {
                newZoneMarker?.remove()
                newZoneMarker = new mapboxgl.Marker({ color: '#314cce' }).setLngLat(this.props.selectedVille).addTo(this.state.maps);
                this.state.maps.flyTo({ center: this.props.selectedVille, zoom: 7 });
            } else if (!this.props.selectedVille) {
                newZoneMarker?.remove()
                newZoneMarker = null

                this.state.maps.flyTo({ center: [this.state.lng, this.state.lat], zoom: this.state.zoom });
                this.state.markers.forEach(item => { item.remove(); })
            }
            this.setState({ ...this.state, markers: newMarkers, zoneMarker: newZoneMarker })
        }
    }

    componentDidMount() {
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: this.props.selectedVille ? this.props.selectedVille : [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });
        let newMarkers = []
        let newZoneMarker = null
        map.on('load', () => {
            // Affiche les intervenants
            if (this.props.intervenantLocation) {
                this.props.intervenantLocation.forEach(marker => {
                    var popup = new mapboxgl.Popup({ offset: 25 }).setHTML(marker.properties.description);
                    newMarkers.push(new mapboxgl.Marker({ color: "red" }).setLngLat(marker.geometry.coordinates).setPopup(popup).addTo(map))
                });
            }
            // Affiche la ville sélectionnée
            if (this.props.selectedVille) {
                newZoneMarker = new mapboxgl.Marker({ color: '#314cce' })
                    .setLngLat(this.props.selectedVille)
                    .addTo(map);
                map.flyTo({ center: this.props.selectedVille, zoom: 5 });
            }

            // Affiche la ville du magasin de l'intervention
            if (this.props.siteCoordinates) {
                new mapboxgl.Marker({ color: 'green' })
                    .setLngLat(this.props.siteCoordinates)
                    .addTo(map);
            }

            this.setState(prevState => ({ ...prevState, markers: newMarkers, maps: map, zoneMarker: newZoneMarker }));
        });
    }

    render() {

        return (
            <div style={{ height: "100%", width: "100%", overflow: "hidden", position: "absolute" }}>
                <div ref={el => this.mapContainer = el} className="mapContainer" />
            </div>
        )
    }
}

export default Mapbox

