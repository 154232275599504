import React from 'react'
import { Stack, Link, Typography } from '@mui/material';
import { useLottie } from "lottie-react";
import NotFound from "../../assets/notfound.json"
const LOTTIESTAB = {
  1: {
    loop: true,
    autoplay: true,
    animationData: NotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
    height: 400,
    width: 400,
  },
};

export default function PageNotFoundFicheIntervention() {
  const { View } = useLottie(LOTTIESTAB[1], { height: LOTTIESTAB[1].height, width: LOTTIESTAB[1].width });
  return (
    <Stack sx={{ height: "100%" }} p={3} textAlign="center" alignContent="center" alignItems="center" justifyContent="center">
      <Typography variant='h3'>
        La page que vous souhaitez consulter n'est pas disponible 
      </Typography>
      {View}
      <Typography variant='h3'>
        Pour plus d'informations
      </Typography>
      <Stack spacing={0.5}>
        <Typography variant='h6'>
          RETROUVEZ-NOUS :
        </Typography>
        <Typography>
          Mastore Lyon
          69500 Bron
          Tél : 04.78.41.61.49
        </Typography>
        <Typography>
          Mastore Ouest
          17440 Aytré
          Tél : 06.49.60.97.57
        </Typography>
        <Link href="https://mastore.fr/">www.mastore.fr</Link>
      </Stack>
    </Stack>
  )
}
