const templateEmail = (attribut, infoIntervention, objectMail, link) => {
    const { enseigne, numero_magasin, ville, objet, id_client, id_mastore, specificite_technique, specialite } = infoIntervention;

    const header = "Bonjour,\n\n";
    const footer = "\n\nCordialement,\n\nLe Service Maintenance\nmaintenance@mastore.fr\n04 78 41 61 49";
    let body = "";

    switch (attribut) {
        case "Devis Client":
            body = `Vous trouverez ci-joint le ${objectMail} concernant la demande:
Enseigne: ${enseigne} N°${numero_magasin}
Ville: ${ville}
Objet: ${objet}
DI Client: ${id_client} , DI Mastore: ${id_mastore}

Notre Service Maintenance reste à votre disposition en cas de problèmes.

Merci pour votre confiance.`;
            break;

        case "Fiche d'Intervention":
            body = `Vous trouverez ci-joint une ${attribut} émise par le Service Maintenance Mastore:
Enseigne: ${enseigne} N°${numero_magasin}
Ville: ${ville}
Objet: ${objet}
Spécificité Technique: ${specificite_technique}


Voici lien pour remplir votre rapport d'intervention: ${link}

Information importante:
Se présenter comme intervenant de la société Mastore et ne pas communiquer les prix/devis à l'Enseigne.

Merci de faire apparaître impérativement sur vos factures:
    Pour un Auto-entrepreneur:  'TVA non applicable, article 293B du CGI.'
    Pour une Société:           'Autoliquidation.'

Notre Service reste à votre disposition en cas de problèmes ou pour toutes autres questions.`;
            break;

        case "Fiche Maintenance":
            body = `Vous trouverez ci-joint les informations concernant votre prochaine maintenance:
Enseigne: ${enseigne} N°${numero_magasin}
Ville: ${ville}
Objet: ${objet}
Spécificité Technique: ${specificite_technique}

Notre Service reste à votre disposition en cas de problèmes ou pour toutes autres questions.`;
            break;

        case "Rapport":
        case "Pré Rapport":
            body = `Vous trouverez ci-joint le ${attribut} concernant votre demande d'intervention:
Enseigne: ${enseigne} N°${numero_magasin}
Ville: ${ville}
Objet: ${objet}
DI Client: ${id_client}

L'intervention s'est déroulée dans les meilleures conditions.
${attribut === "Pré Rapport" ? "Vous le recevrez dans les plus brefs délais le rapport de fin d'intervention.\n" : ""}
Le Service Maintenance Mastore vous remercie et reste à votre disposition en cas de problème.`;
            break;

        case "Rapport préventive":
            body = `Veuillez trouver ci-joint le rapport de la maintenance ${specialite} du mois de ....

Notre service reste à votre disposition pour toutes autres questions.`;
            break;

        case "Bon de Commande":
        case "Demande de Devis":
            body = `Vous trouverez ci-joint une ${attribut} émise par le Service Maintenance Mastore:
Enseigne: ${enseigne} N°${numero_magasin}
Ville: ${ville}
Objet: ${objet}
Spécificité Technique: ${specificite_technique}

Information importante:
Se présenter comme intervenant de la société Mastore et ne pas communiquer les prix/devis à l'Enseigne.

Merci de faire apparaître impérativement sur vos factures:
    Pour un Auto-entrepreneur:  'TVA non applicable, article 293B du CGI.'
    Pour une Société:           'Autoliquidation.'

Notre Service reste à votre disposition en cas de problèmes ou pour toutes autres questions.`;
            break;
        default:
            body = "Nous restons à votre disposition pour toute information complémentaire.";
            break;
    }

    return `${header}${body}${footer}`;
};

export default templateEmail;
