import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Stack,
  Checkbox,
  Typography
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "components/UI/Header/Header";
import { b64toBlob } from "utils/handleFiles";
import { withSnackbar } from "notistack";
import { useSnackbar } from "notistack";
import QuantityInput from "../../components/QuantityInput";
import StockService from "services/stocksService";
import Autocomplete from "@mui/material/Autocomplete";

const stockService = new StockService();

const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  zIndex: 1300,
  maxWidth: "800px",
  width: "100%",
  maxHeight: "90vh",
  overflowY: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "35px !important",
  height: "35px !important",
  textAlign: "center",
}));

const CreateFicheCommandeStock = ({ infoInternvention, user, filteredIntervenant, filter, onChangeFilter }) => {
  const [rows, setRows] = useState([
    { produit: "", reference: "", quantite: 0 },
  ]);
  const [stockOptions, setStockOptions] = useState([]);
  const [selectedSociete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [lieu_de_stockage, setLieu_de_stockage] = useState(null);
  const [selectedStocks, setSelectedStocks] = useState([]);

  useEffect(() => {
    stockService
      .getStockByIntervenantAndClient(lieu_de_stockage, infoInternvention.client)
      .then((response) => {
        setStockOptions(response);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des stocks :", error);
      });
  }, [lieu_de_stockage, infoInternvention.client]);

  console.log("stockOptions:", stockOptions);
  const handleAddRow = () => {
    setRows([...rows, { produit: "", reference: "", quantite: 0 }]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };


 const handleCheckboxChange = (stock, isChecked) => {
  if (isChecked) {
    setSelectedStocks([...selectedStocks, { ...stock, quantite: 1 }]);
  } else {
    setSelectedStocks(selectedStocks.filter((item) => item.id_stock !== stock.id_stock));
  }
};

const handleQuantityChange = (index, value) => {
  const selectedStock = selectedStocks.find(item => item.id_stock === stockOptions[index].id_stock);

  if (!selectedStock) {
    enqueueSnackbar("Veuillez d'abord cocher l'élément avant de modifier la quantité.", {
      variant: "error",
    });
    return;
  }

  const updatedStocks = [...selectedStocks];
  updatedStocks[index].quantite = value;
  setSelectedStocks(updatedStocks);
};


const handleCreateFiche = (preview) => {
  const produitRows = selectedStocks.map((row) => row.product);
  const referenceRows = selectedStocks.map((row) => row.reference);
  const quantiteRows = selectedStocks.map((row) => row.quantite);
  const marqueRows = selectedStocks.map((row) => row.marque);
  const typeRows = selectedStocks.map((row) => row.type);

  const ficheCommandeRequestBody = {
    pilote: user.firstname + " " + user.lastname,
    id_mastore: infoInternvention.id_mastore,
    ObjetMail: "Commande de Stock",
    infos_commande: {
      produit_rows: produitRows,
      reference_rows: referenceRows,
      quantite_rows: quantiteRows,
      marque_rows: marqueRows,
      type_rows: typeRows,
      id_stocks: selectedStocks.map((row) => row.id_stock),
      lieu_de_stockage: selectedStocks.map((row) => row.lieu_de_stockage),
    },
    SendTo: [""],
    Contact: lieu_de_stockage,
  };

  stockService
    .createFicheCommande(preview, ficheCommandeRequestBody)
    .then((data) => {
      if (preview) {
        const blob = b64toBlob(data, "application/pdf");
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      } else {
        enqueueSnackbar("Fiche Commande créée avec succès", {
          variant: "success",
        });
        // Refresh the page
        window.location.reload();
      }
    })
    .catch((error) => {
      console.error(
        "Erreur lors de la création de la fiche commande :",
        error
      );
    });
};


  const handleMaxValueReached = () => {
    enqueueSnackbar("La quantité maximale pour ce produit est atteinte.", {
      variant: "warning",
    });
  };

  const handleAutocompleteChange = (event, newValue) => {
  if (newValue) {
    setLieu_de_stockage(newValue.societe);
  }
};
    const uniqueIntervenants = Array.from(
    new Set(filteredIntervenant.map((item) => item.societe))
  )
    .filter((societe) => societe && societe.trim() !== "")
    .map((societe) => ({
      societe,
    }));

  const handleSubmit = (e) => {
    e.preventDefault();
  };


  return (
       <Box
      sx={{
        width: { xl: "65vw", md: "70vw", sm: "70vw", xs: "95vw" },
        maxHeight: "80vh",
        padding: "15px",
      }}
    >
      <Header>Fiche de commande</Header>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Autocomplete
            id="lieu_de_stockage"
            value={lieu_de_stockage ? { societe: lieu_de_stockage } : null}
            onChange={handleAutocompleteChange}
            options={uniqueIntervenants}
            getOptionLabel={(option) => option.societe ?? ""}
            isOptionEqualToValue={(option, value) =>
              option.societe === value?.societe
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Intervenant"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          />
        </Grid>
        {lieu_de_stockage != null ? (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Ajouter</StyledTableCell>
                <StyledTableCell>Produit</StyledTableCell>
                <StyledTableCell>Référence</StyledTableCell>
                <StyledTableCell>Marque</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell sx={{ width: "15%", textAlign: "center" }}>
                  Quantité
                </StyledTableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {stockOptions.map((stock, index) => (
                <TableRow key={stock.id_stock}>
                  <StyledTableCell>
                    <Checkbox
                      checked={!!selectedStocks.find(item => item.id_stock === stock.id_stock)}
                      onChange={(event) =>
                        handleCheckboxChange(stock, event.target.checked)
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>{stock.product}</StyledTableCell>
                  <StyledTableCell>{stock.reference}</StyledTableCell>
                  <StyledTableCell>{stock.marque}</StyledTableCell>
                  <StyledTableCell>{stock.type}</StyledTableCell>
                  <StyledTableCell sx={{ width: "15%", textAlign: "center" }}>
                    <QuantityInput
                      value={
                        selectedStocks.find(item => item.id_stock === stock.id_stock)?.quantite || 0
                      }
                      onChange={(newValue) => handleQuantityChange(index, newValue)}
                      max={stock.quantity}
                    />
                  </StyledTableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        ) : (
          <Stack style={{ paddingTop: "3%" }}>
            <Typography variant="h1" align="center" textAlign={"center"} color="rgba(99, 115, 129, 0.3)">
              Aucun produits disponibles
            </Typography>
          </Stack>)}
        
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={() => handleCreateFiche(true)}>
              Prévisualiser
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCreateFiche(false)}
            >
              Enregistrer
            </Button>
          </Stack>
        </Box>
      </form>  </Box>

  );
};

export default withSnackbar(CreateFicheCommandeStock);
