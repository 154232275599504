export const InformationInterventionRights = (user : any) => {
    if (user.administrateur) {
        return 0
    } else if (user.role === 'Comptable') {
        return 1
    } else if (user.role === 'Responsable maintenance' || user.role === 'Développeur') {
        return 2
    } else if (user.role === 'Chargé(e) d\'affaires') {
        if (user.entreprise === 'Mastore Ouest')
            return 3
        else
            return 2
    } else if (user.role === 'Assistant(e)') {
        return 4
    } else if (user.role === 'Chargé(e) d\'affaires adjoint(e)') {
        return 3
    } else {
        return 4
    }
}