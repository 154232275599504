import React from 'react';
import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NAVBAR } from 'config';
import { AppBar, Stack, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArchiveIcon from '@mui/icons-material/Archive';
import DoneIcon from '@mui/icons-material/Done';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import Chat from 'components/UI/Chat/Chat';
import { useSnackbar } from 'notistack';

const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'update' })(
    ({ theme, update }) => ({
        background: "white",
        width: '220px',
        variant: update ? 'none' : 'outlined',
        '&:hover': {
            variant: update ? 'none' : 'outlined',
        },
        ...(update && {
            background: theme.palette.warning.main,
            color: 'white',
            border: "none",
            '&:hover': {
                background: alpha(theme.palette.warning.main, 0.8),
                border: "none"
            }
        }),
    })
);

const ButtonArchiver = styled(Button)(({ theme }) => ({
    width: '120px',
    background: "white",
    color: '#4B5563',
    borderColor: "#4B5563",
    '&:hover': {
        background: alpha("#4B5563", 0.8),
        color: "white",
    }
}));

const ButtonFinish = styled(Button)(({ theme }) => ({
    width: '120px',
    color: "rgb(76, 175, 80)",
    background: "white",
    '&:hover': {
        background: alpha("rgb(76, 175, 80)", 0.8),
        color: "white",
    }
}));

const StyledAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'collapseClick' })(
    ({ theme, collapseClick }) => ({
        top: 'auto',
        bottom: 0,
        padding: theme.spacing(0, 2),
        background: '#fcfcfc',
        height: '5vh',
        borderTop: "1px dashed rgba(145, 158, 171, 0.24)",
        width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        ...(!collapseClick && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        }),
    }),
);

const ButtonBar = ({ update, archiveIntervention, archives, OnchangeIntervention, saveIntervention, infoIntervention, openDialogFinish, user }) => {
    const { collapseClick } = useCollapseDrawer();
    const { enqueueSnackbar } = useSnackbar();
    const [disable, setDisable] = React.useState(false);
    const disableChat = infoIntervention["chat_client"];

    const onClickSave = () => {
        setDisable(true);
        saveIntervention();
    };

    const handleDialogFinish = () => {
        const { contact_client, status } = infoIntervention;
        if ((contact_client === "" || contact_client === "select") && status !== "TERMINEE") {
            enqueueSnackbar('Veuillez choisir un contact client dans les informations générales de l\'intervention', { variant: 'warning' });
            return;
        }
        if (status === 'TERMINEE') {
            enqueueSnackbar('L\'intervention est déjà TERMINÉE', { variant: 'info' });
            return;
        }
        if (update) {
            enqueueSnackbar('Veuillez sauvegarder avant de passer l\'intervention en TERMINÉE', { variant: 'warning' });
            return;
        }
        openDialogFinish();
    };

    return (
        <StyledAppBar position="fixed" collapseClick={collapseClick}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" height={1}>
                {disableChat ? <Chat intervention={infoIntervention} disableChat={disableChat} /> : null}
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                    <ButtonArchiver variant='outlined' onClick={archiveIntervention} disabled={archives || OnchangeIntervention !== ""} startIcon={<ArchiveIcon />}>
                        Archiver
                    </ButtonArchiver>
                    {user.pilote && infoIntervention.status === "EN COURS" ?
                        <ButtonFinish variant='outlined' status={infoIntervention.status} onClick={handleDialogFinish} startIcon={<DoneIcon />}>
                            Terminer
                        </ButtonFinish> : null
                    }
                    {OnchangeIntervention !== "" && OnchangeIntervention !== user.userID ? (
                        <Button onClick={saveIntervention} disabled={true} variant="contained" startIcon={<SaveIcon />}>
                            En cours de modification par {OnchangeIntervention}
                        </Button>
                    ) : (
                        <StyledButton onClick={onClickSave} update={update} disabled={disable} variant="outlined" startIcon={<SaveIcon />}>
                            {infoIntervention.last_save ? infoIntervention.last_save : "Sauvegarder"}
                        </StyledButton>
                    )}
                </Stack>
            </Stack>
        </StyledAppBar>
    );
};

export default ButtonBar;
