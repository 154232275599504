import React from 'react';

// LIB
import { useSnackbar } from 'notistack';

// MUI
import { IconButton } from '@mui/material';

// styles
import { TruncatedText, ButtonOpen } from '../UI/DisplayAttachment/DisplayAttachmentStyle';

interface OpenFileProps {
    icon: React.ReactNode;
    url?: string;
    base64_file?: string;
    attachment?: boolean;
    disabled?: boolean;
}

const OpenFile: React.FC<OpenFileProps> = ({ icon, url, base64_file, attachment, disabled }) => {
    const { enqueueSnackbar } = useSnackbar();

    const onClick = () => {
        if (base64_file) {
            if (base64_file.trim().length > 0) {
                try {
                    const base64String = base64_file.includes(',') ? base64_file.split(',')[1] : base64_file;
                    const binaryString = window.atob(base64String);
                    const bytes = new Uint8Array(binaryString.length);
                    for (let i = 0; i < binaryString.length; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }

                    let mimeType = 'application/pdf';
                    let fileExtension = '.pdf';

                    if (base64_file.startsWith('data:image/')) {
                        mimeType = base64_file.split(';')[0].split(':')[1]; // 'image/png', 'image/jpeg', etc.
                        fileExtension = mimeType.split('/')[1]; // Get the extension from the MIME type
                    }

                    const blob = new Blob([bytes], { type: mimeType });
                    const fileURL = URL.createObjectURL(blob);

                    // Open the file in a new tab
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.target = '_blank'; // Open in a new tab
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                } catch (error) {
                    console.error("Error decoding base64 string:", error);
                    enqueueSnackbar("Erreur lors de la décodage du fichier base64", { variant: 'error' });
                }
            } else {
                enqueueSnackbar("La chaîne base64 est vide", { variant: 'error' });
            }
        } else if (url) {
            // Handle URL opening in a new tab
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank'; // Open in a new tab
            link.rel = 'noopener noreferrer'; // Recommended for security
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            enqueueSnackbar("Erreur lors de la récupération du fichier", { variant: 'error' });
        }
    };

    return attachment ? (
        <ButtonOpen
            size='small'
            onClick={onClick}
            variant="outlined"
            disabled={disabled}
        >
            <TruncatedText>{attachment}</TruncatedText>
        </ButtonOpen>
    ) : (
        <IconButton
            aria-label="file"
            size="small"
            onClick={onClick}
            color="primary"
            disabled={disabled}
        >
            {icon}
        </IconButton>
    );
};

export default OpenFile;
